import { Controller } from 'stimulus'
import Swiper, {Autoplay, EffectFade} from 'swiper'
Swiper.use([Autoplay, EffectFade])

export default class extends Controller {

  connect() {
    this.initHeroSlider()
  }

  initHeroSlider() {
    new Swiper(this.element.querySelector('.js-heroSlider'), {
      slidesPerView: 1,
      loop: true,
      speed: 3000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      allowTouchMove: false,
    })
  }

  scrollPage() {
    let nextSection = this.element.nextElementSibling;
    // check if the next section exists
    if (!nextSection) return;

    // check if the next section tag is a section tag
    if (nextSection.tagName !== 'SECTION') {
      // if not, get the next section
      nextSection = nextSection.nextElementSibling;
    }

    nextSection.scrollIntoView({ behavior: 'smooth' })
  }

}
